<template lang="pug">
v-container
    v-text-field(v-model='searchString', dense, outlined, color='brand', label='Search', :loading='fetchingParts', @keyup='handlePartSearchInput')
    v-data-table(:loading='fetchingParts', :items='parts', :headers='headers', :footer-props="{'items-per-page-options':itemsPerPageOptions}", :items-per-page.sync='itemsPerPage', dense, :page.sync='currentPageNumber', :server-items-length='totalItems', height='75vh')
        template(v-slot:item.custom_description='{item}')
            v-btn(icon, @click='selectPart(item)', small)
                v-icon edit
            span {{ item.custom_description }}
    v-dialog(v-model='descriptionDialog')
        v-card(light)
            v-card-title Edit Part Description
            v-card-text
                v-textarea(v-model='selectedPart.custom_description', outlined, color='brand')
            v-card-actions
                v-spacer
                v-btn(text, @click='descriptionDialog = false') Cancel
                v-btn.white--text(color='brand', @click='saveDescription', :loading='savingPart') Save
</template>
<script>
import {debounce} from 'lodash';

export default {
    props: {},
    data () {
        return {
            fetchingParts: true,
            updatingPart: false,
            itemsPerPageOptions: [10, 20, 50, 100, 200],
            itemsPerPage: 20,
            currentPageNumber: 1,
            searchString: '',
            totalItems: 0,
            parts: [],
            headers: [
                {
                    text: 'Product ID',
                    value: 'product_id'
                },
                {
                    text: 'Jonas Description',
                    value: 'description'
                },
                {
                    text: 'Custom Description',
                    value: 'custom_description'
                }
            ],
            descriptionDialog: false,
            selectedPart: {},
            oldDescription: '',
            savingPart: false
        };
    },
    computed:{},
    methods: {
        fetchParts: debounce(async function () {
            this.fetchingParts = true;
            const requestData = {
                terms: this.searchString.toLowerCase().split(' '),
                offset: (this.currentPageNumber - 1) * this.itemsPerPage,
                limit: this.itemsPerPage
            };
            const searchResults = await this.sendCommand({
                action: 'searchParts',
                parameters: requestData
            });
            this.parts = searchResults.parts;
            this.totalItems = searchResults.count;
            this.fetchingParts = false;

        }, 500),
        handlePartSearchInput (event) {
            this.fetchingParts = true;
            this.fetchParts(event.target.value);
        },
        selectPart (part) {
            this.oldDescription = part.custom_description;
            this.selectedPart = part;
            this.descriptionDialog = true;
        },
        resetDescription () {
            this.selectedPart.custom_description = this.oldDescription;
        },
        async saveDescription () {
            this.savingPart = true;
            const results = await this.sendCommand({
                action: 'updateCustomPartDescription',
                parameters: {
                    partData: this.selectedPart
                }
            });
            this.descriptionDialog = false;
            this.$nextTick(() => {
                const partToUpdate = this.parts.find(part => part.id === results.id);
                Object.assign(partToUpdate, results);
            });
            this.savingPart = false;
        }
    },
    watch: {
        itemsPerPage () {
            this.fetchingParts = true;
            this.fetchParts();
        },
        currentPageNumber () {
            this.fetchingParts = true;
            this.fetchParts();
        },
        descriptionDialog (visible) {
            if (!visible) {
                this.resetDescription();
                this.selectedPart = {};
            }
        }
    },
    mounted () {
        this.fetchParts();
    }
};
</script>
